<template>
    <div class="aftersale-content">
        <div class="head-con">
            <h3>售后管理</h3>
            <el-input v-model="input3" placeholder="请输入商品名称或订单号搜索" size="mini">
                <template #append>搜索</template>
            </el-input>
        </div>
        <div class="content">
            <img src="@/assets/image/no_order.png" />
            <p>你还没有相关的订单哦~</p>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped lang="scss">
    .aftersale-content{
        width: 100%;
        height: 100%;
        background: #fff;
        position: relative;
        display: flex;
        flex-direction: column;
        .head-con{
            width: 100%;
            height: 56px;
            border-bottom: 1px solid #E6E6E6;
            display: flex;
            align-items: center;
            justify-content: space-between;
            h3{
                color: #020202;
                font-size: 16px;
                font-weight: bold;
                padding-left: 16px;
                line-height: 56px;
            }
            .addcon{
                display: flex;
                align-items: center;
                padding-right: 15px;
                p{
                    color: #D4282D;
                    font-size: 14px;
                    line-height: 14px;
                    cursor: pointer;
                    padding-right: 5px;
                }
            }
        }
        .content{
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            p{
                font-size: 16px;
                line-height: 16px;
                color: #888888;
                margin-top: 20px;
            }
        }
    }

    .el-input-group{
        width: 290px;
        position: absolute;
        top: 15px;
        right: 15px;
    }
    ::v-deep .el-input__inner{
        border-radius: 0px;
    }
    ::v-deep .el-input-group__append, .el-input-group__prepend{
        border-radius: 0px;
    }
</style>